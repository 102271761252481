/* switch settings 👇 */

.switch {
  /* slider */
  --slider-width: 64px;
  --slider-height: 28px;
  --slider-border-radius: 999px;
  --slider-bg: #c5efcb;
  /* circle */
  --circle-diameter: 40px;
  --circle-bg: #fff;
  --circle-checked-bg: #fff;
  --circle-position: calc((var(--circle-diameter) - var(--slider-height)) / 2);
  --circle-transition-duration: 0.2s;
  --circle-shadow: 0 0 3px rgba(166, 166, 166, 0.445);
  /* icons */
  --icon-size: 20px;
  --icon-play-color: #000;
  --icon-pause-color: var(--circle-checked-bg);
}

.switch input {
  display: none;
}

.switch .slider svg {
  -webkit-transition: all var(--circle-transition-duration);
  -o-transition: all var(--circle-transition-duration);
  transition: all var(--circle-transition-duration);
  width: var(--icon-size);
  color: var(--icon-pause-color);
  height: auto;
  display: inline-block;
  vertical-align: top;
  position: absolute;
}

.switch .slider svg.play {
  color: var(--icon-play-color);
  opacity: 0;
  visibility: hidden;
  margin-left: 2px;
}

.switch .slider {
  width: var(--slider-width);
  height: var(--slider-height);
  border-radius: var(--slider-border-radius);
  background: var(--slider-bg);
  position: relative;
}

.switch .circle {
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  background: var(--circle-bg);
  left: calc(var(--circle-position) * -1);
  top: calc(var(--circle-position) * -1);
  -webkit-transition: all var(--circle-transition-duration);
  -o-transition: all var(--circle-transition-duration);
  transition: all var(--circle-transition-duration);
  -webkit-box-shadow: var(--circle-shadow);
  box-shadow: var(--circle-shadow);
  border-radius: inherit;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* actions */

.switch input:checked + .slider .circle {
  left: calc(100% - var(--circle-position) - var(--slider-height));
  background: var(--circle-checked-bg);
}

.switch input:checked + .slider .circle svg.pause {
  opacity: 0;
  visibility: hidden;
}

.switch input:checked + .slider .circle svg.play {
  opacity: 1;
  visibility: visible;
}

input:checked + .slider {
  background-color: #de6c83;
}
