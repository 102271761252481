@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-[#D67C79];
}

.onboarding-input::selection {
  color: rgb(156 163 175 / var(--tw-text-opacity));
  background: highlight;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top, 10px) env(safe-area-inset-right, 10px)
    env(safe-area-inset-bottom, 10px) env(safe-area-inset-left, 10px);
}

.ios-safe-bottom {
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

input {
  font-size: 16px !important;
}
